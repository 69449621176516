import {addReport} from "../utils/firebaseUtils";
import Form from "./Form";
var sending = false;
function ReportForm() {
    return (<Form title="הוספת דיווח"
    showDropdown={true}
    detailsPlaceHolder="פרטי הדיווח"
    buttonTitle="הוסיפו דיווח"
    onSubmit={handleSubmit} />);
}

function handleSubmit(data) {
    if (!sending) {
        sending = true;
        addReport(data.name, data.details, data.city).then(() => {
            sending = false;
        }).catch(() => {
            sending = false;
        });
    }
}
export default ReportForm;