import {addStory} from "../utils/firebaseUtils";
import Form from "./Form";
var sending = false;
function StoryForm() {
    return (<Form title="הוספת סיפור"
    showDropdown={false}
    detailsPlaceHolder="פרטי הסיפור"
    buttonTitle="הוסיפו סיפור"
    onSubmit={handleSubmit} />);
}

function handleSubmit(data) {
    if (!sending) {
        sending = true;
        addStory(data.name, data.details).then(() => {
            sending = false;
        }).catch(() => {
            sending = false;
        });
    }
    
}
export default StoryForm;